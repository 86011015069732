/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 11
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'prodteamsnapforbusiness' | 'devteamsnapforbusiness';

export const ApiKey: Record<Environment, string> = {
  prodteamsnapforbusiness: '2061a24721515ff95b7915e1fec20ef6',
  devteamsnapforbusiness: '76261d25d4907e3750f0a8a62de1578d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '11',
    branch: 'main',
    source: 'web',
    versionId: 'cfd6282e-4e58-43ad-9c0d-feeed3f8cca4'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The email address of the current user
   */
  email?: string;
  /**
   * The date in which a sports org was provisioned / created on a TeamSnap product
   */
  org_creation_date?: string;
  /**
   * The total pay volume a sports org has processed with TeamSnap at the time of an event
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  org_pay_volume?: number;
  /**
   * The number of members within a sports org at the time of an event
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  org_size?: number;
  /**
   * The sport assigned to a sports organization
   */
  org_sport?: string;
  /**
   * The users active org id.
   */
  selected_org_id?: string;
  /**
   * The role of the staff member who triggers the event.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Organization Owner, Organization Admin, Program Admin, Program Coordinator |
   */
  selected_org_staff_role?: "Organization Owner" | "Organization Admin" | "Program Admin" | "Program Coordinator";
  /**
   * The unique identifier for the program the user has loaded when the user is identified to amplitude.
   */
  selected_program_id?: string;
}

export interface EndUserRegistrationFormViewedProperties {
  /**
   * Unique registration form id assigned to each from in TSB registration.
   */
  form_id: string;
  /**
   * The unique identifier of the organization a team is administered by.
   */
  organization_id: string;
  /**
   * The name of the TeamSnap for Business organization.
   */
  organization_name: string;
  /**
   * The unique identifier of a program the team is associated with.
   */
  program_id: string;
  /**
   * The unique identifier of an organization season the team is associated with
   */
  season_id: string;
}

export interface RegWizardStepCompletedProperties {
  /**
   * Unique registration form id assigned to each from in TSB registration.
   */
  form_id: string;
  /**
   * The unique identifier of the organization a team is administered by.
   */
  organization_id: string;
  /**
   * The name of the TeamSnap for Business organization.
   */
  organization_name: string;
  /**
   * The unique identifier of a program the team is associated with.
   */
  program_id: string;
  /**
   * \- Program
   *
   * \- Season
   *
   * \- Name
   *
   * \- Schedule
   *
   * \- Group Names
   *
   * \- Registration Fees
   *
   * \- Eligibility Rules & Auto-Rostering
   *
   * \- Capacity Limit & Waitlist
   *
   * \- Questions & Logic
   *
   * \- Digital Agreements
   *
   * \- Deposit Account
   *
   * \- Installment Plans
   *
   * \- Discounts
   *
   * \- Confirmation Message
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Program, Season, Name, Schedule, Group Names, Registration Fees, Eligibility Rules & Auto-Rostering, Capacity Limit & Waitlist, Questions & Logic, Digital Agreements, Deposit Account, Installment Plans, Discounts, Confirmation Message |
   */
  registration_wizard_chapter:
    | "Program"
    | "Season"
    | "Name"
    | "Schedule"
    | "Group Names"
    | "Registration Fees"
    | "Eligibility Rules & Auto-Rostering"
    | "Capacity Limit & Waitlist"
    | "Questions & Logic"
    | "Digital Agreements"
    | "Deposit Account"
    | "Installment Plans"
    | "Discounts"
    | "Confirmation Message";
  /**
   * This property will be used to identify the specific step within all chaperts of the setup wizard (Ie. Program/Season/Name/Schedule are all steps under the "General" chapter.)
   */
  registration_wizard_step: string;
  /**
   * The unique identifier of an organization season the team is associated with
   */
  season_id: string;
}

export interface RegWizardStepSkippedProperties {
  /**
   * Unique registration form id assigned to each from in TSB registration.
   */
  form_id: string;
  /**
   * The unique identifier of the organization a team is administered by.
   */
  organization_id: string;
  /**
   * The name of the TeamSnap for Business organization.
   */
  organization_name: string;
  /**
   * The unique identifier of a program the team is associated with.
   */
  program_id: string;
  /**
   * Chapter is defined in code by "breadcrumb", this is the grouping of steps a user navigates to complete registration form setup in TSB Reg 2.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | General, Registration Options, Form Builder, Checkout Settings, Confirmation Message |
   */
  registration_wizard_chapter:
    | "General"
    | "Registration Options"
    | "Form Builder"
    | "Checkout Settings"
    | "Confirmation Message";
  /**
   * This property will be used to identify the specific step within all chaperts of the setup wizard (Ie. Program/Season/Name/Schedule are all steps under the "General" chapter.)
   */
  registration_wizard_step: string;
  /**
   * The unique identifier of an organization season the team is associated with
   */
  season_id: string;
}

export interface RegistrationWizardViewedProperties {
  /**
   * The unique identifier of the organization a team is administered by.
   */
  organization_id: string;
  /**
   * The name of the TeamSnap for Business organization.
   */
  organization_name: string;
  /**
   * The unique identifier of a program the team is associated with.
   */
  program_id: string;
  /**
   * The unique identifier of an organization season the team is associated with
   */
  season_id: string;
}

export interface ScreenViewedProperties {
  /**
   * Property to define screen views in TSB.  TSB screen view pages with corresponding screen name can be found [here](https://miro.com/app/board/uXjVKHTMMoQ=/?userEmail=alan.hull@teamsnap.com&openComment=3458764590075449749&mid=12934428&utm_source=notification&utm_medium=email&utm_campaign=&utm_content=reply-to-mention).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Boom Screen, Org Programs List, Org Programs Report, Program Seasons List, Program Wizard: Program Type, Season Wizard: Create Season, Org Financials: Outstanding, Org Financials: Transactions, Org Calendar, Org Member Directory, Org Member Profile, Org Staff List, Org Registration List, Reg 2: Registration Report, Reg 1: Registration Report, Reg 1: Registration List, Reg 1: Registration Transaction Summary, Reg 1: Form Overview, Reg 1: Form Preview, Reg 2: Setup Wizard - Select Program, Reg 1: New Registration Form, Org Messages List, Org Settings General, Merchant Accounts List, Season Divisions List, New Division, Season Financial Settings, Season Financial Reporting: Payments, New Invoice, Season Registration List, Season Rostering, Season Participants List, Season Member Profile, Season Add Participant, Season Import Participants, Season Schedule, Season Schedule Import, Season Locations List, Season New Event, Season Messages List, Season New Message, Health Check Questions, Season Settings, Org New Message, Division Rostering, Division Members List, Division Messages List, Org Financials: Deposits, Season Invoicing, Member Profile: History, Member Profile: Invoices, Member Profile: Registration, Member Profile: Participation, Registrant Sign In, Registrant New User, Registrant Password, Registrant Password Reset, Registrant Summary, Registrant Select Member, Registrant Select Option, Registrant Answer Questions, Registrant Cart, Registrant Waiver, Registrant Choose Installment Plan, Registrant Payment Method, Registrant Confirmation Message |
   */
  screen_name:
    | "Boom Screen"
    | "Org Programs List"
    | "Org Programs Report"
    | "Program Seasons List"
    | "Program Wizard: Program Type"
    | "Season Wizard: Create Season"
    | "Org Financials: Outstanding"
    | "Org Financials: Transactions"
    | "Org Calendar"
    | "Org Member Directory"
    | "Org Member Profile"
    | "Org Staff List"
    | "Org Registration List"
    | "Reg 2: Registration Report"
    | "Reg 1: Registration Report"
    | "Reg 1: Registration List"
    | "Reg 1: Registration Transaction Summary"
    | "Reg 1: Form Overview"
    | "Reg 1: Form Preview"
    | "Reg 2: Setup Wizard - Select Program"
    | "Reg 1: New Registration Form"
    | "Org Messages List"
    | "Org Settings General"
    | "Merchant Accounts List"
    | "Season Divisions List"
    | "New Division"
    | "Season Financial Settings"
    | "Season Financial Reporting: Payments"
    | "New Invoice"
    | "Season Registration List"
    | "Season Rostering"
    | "Season Participants List"
    | "Season Member Profile"
    | "Season Add Participant"
    | "Season Import Participants"
    | "Season Schedule"
    | "Season Schedule Import"
    | "Season Locations List"
    | "Season New Event"
    | "Season Messages List"
    | "Season New Message"
    | "Health Check Questions"
    | "Season Settings"
    | "Org New Message"
    | "Division Rostering"
    | "Division Members List"
    | "Division Messages List"
    | "Org Financials: Deposits"
    | "Season Invoicing"
    | "Member Profile: History"
    | "Member Profile: Invoices"
    | "Member Profile: Registration"
    | "Member Profile: Participation"
    | "Registrant Sign In"
    | "Registrant New User"
    | "Registrant Password"
    | "Registrant Password Reset"
    | "Registrant Summary"
    | "Registrant Select Member"
    | "Registrant Select Option"
    | "Registrant Answer Questions"
    | "Registrant Cart"
    | "Registrant Waiver"
    | "Registrant Choose Installment Plan"
    | "Registrant Payment Method"
    | "Registrant Confirmation Message";
  /**
   * The url of the link tapped
   */
  url: string;
}

export interface SeasonInvoiceClickProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Batch, Invoice |
   */
  season_invoice_tab_clicked: "Batch" | "Invoice";
}

export interface TsbFinancialActionClickedProperties {
  /**
   * Click actions embedded within the report views that navigate away from the Financial page and into season level data
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | View Transactions, View Registration, View Order, View Batch, View Invoice |
   */
  financial_action?: "View Transactions" | "View Registration" | "View Order" | "View Batch" | "View Invoice";
  /**
   * The name of the report tab selected within the Financials section
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Transactions, Outstanding, Deposit |
   */
  financial_report?: "Transactions" | "Outstanding" | "Deposit";
}

export interface TsbFinancialDateRangeChangedProperties {
  /**
   * The date range selection by the user when viewing TSB financial reports
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Today, Last 7 Days, Last 14 Days, Last 30 Days, Month to Date, Year to Date, Custom |
   */
  financial_date_option?:
    | "Today"
    | "Last 7 Days"
    | "Last 14 Days"
    | "Last 30 Days"
    | "Month to Date"
    | "Year to Date"
    | "Custom";
  /**
   * The name of the report tab selected within the Financials section
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Transactions, Outstanding, Deposit |
   */
  financial_report?: "Transactions" | "Outstanding" | "Deposit";
}

export interface TsbFinancialFilterChangedProperties {
  /**
   * The type of filter being applied to TSB financial reports
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Member Name, Program, Source Type, Registration, Invoice, Status, Payment Type, Deposit Account, Clear All |
   */
  financial_filter?:
    | "Member Name"
    | "Program"
    | "Source Type"
    | "Registration"
    | "Invoice"
    | "Status"
    | "Payment Type"
    | "Deposit Account"
    | "Clear All";
  /**
   * The name of the report tab selected within the Financials section
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Transactions, Outstanding, Deposit |
   */
  financial_report?: "Transactions" | "Outstanding" | "Deposit";
  /**
   * The count of results returned to a table
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  page_result_count?: number;
}

export interface TsbFinancialsExportedProperties {
  /**
   * The name of the report tab selected within the Financials section
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Transactions, Outstanding, Deposit |
   */
  financial_report?: "Transactions" | "Outstanding" | "Deposit";
}

export interface TsbHelpLinkClickedProperties {
  /**
   * The unique identifier of the organization a team is administered by.
   */
  organization_id: string;
  /**
   * The name of the TeamSnap for Business organization.
   */
  organization_name: string;
  /**
   * Captures the URL of the page the user is on.
   */
  page_viewed_url: string;
}

export interface TsbRegistrationActionClickedProperties {
  /**
   * Registration actions clicked on the Reg results reports taken by users in TSB
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bulk Action: Export Selected, Bulk Action: Ready to Roster, Bulk Action: Email Selected, Bulk Action: Email All, Bulk Action: Delete Selected, Bulk Action: Accept from Waitlist, Bulk Action: Release from Waitlist, Individual Action: Email, Individual Action: Ready to Roster, Individual Action: Order Details, Individual Action: Roster Profile, Manage Columns, Copy Form Link, Share Form, Edit Form, Go to Rostering |
   */
  registration_action?:
    | "Bulk Action: Export Selected"
    | "Bulk Action: Ready to Roster"
    | "Bulk Action: Email Selected"
    | "Bulk Action: Email All"
    | "Bulk Action: Delete Selected"
    | "Bulk Action: Accept from Waitlist"
    | "Bulk Action: Release from Waitlist"
    | "Individual Action: Email"
    | "Individual Action: Ready to Roster"
    | "Individual Action: Order Details"
    | "Individual Action: Roster Profile"
    | "Manage Columns"
    | "Copy Form Link"
    | "Share Form"
    | "Edit Form"
    | "Go to Rostering";
}

export interface TsbRegistrationExportedProperties {
  /**
   * The type of data exported from registration reports within TSB
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Reg 2: Registration Data, Reg 2: Waivers, Reg 2: Fee Summary, Reg 2: Fee Detail |
   */
  registration_export_type?: "Reg 2: Registration Data" | "Reg 2: Waivers" | "Reg 2: Fee Summary" | "Reg 2: Fee Detail";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EndUserRegistrationFormViewed implements BaseEvent {
  event_type = 'End User Registration Form Viewed';

  constructor(
    public event_properties: EndUserRegistrationFormViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegWizardStepCompleted implements BaseEvent {
  event_type = 'Reg Wizard Step Completed';

  constructor(
    public event_properties: RegWizardStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegWizardStepSkipped implements BaseEvent {
  event_type = 'Reg Wizard Step Skipped';

  constructor(
    public event_properties: RegWizardStepSkippedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationWizardViewed implements BaseEvent {
  event_type = 'Registration Wizard Viewed';

  constructor(
    public event_properties: RegistrationWizardViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScreenViewed implements BaseEvent {
  event_type = 'Screen Viewed';

  constructor(
    public event_properties: ScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeasonInvoiceClick implements BaseEvent {
  event_type = 'Season Invoice Click';

  constructor(
    public event_properties: SeasonInvoiceClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbFinancialActionClicked implements BaseEvent {
  event_type = 'TSB Financial Action Clicked';

  constructor(
    public event_properties?: TsbFinancialActionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbFinancialDateRangeChanged implements BaseEvent {
  event_type = 'TSB Financial Date Range Changed';

  constructor(
    public event_properties?: TsbFinancialDateRangeChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbFinancialFilterChanged implements BaseEvent {
  event_type = 'TSB Financial Filter Changed';

  constructor(
    public event_properties?: TsbFinancialFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbFinancialsExported implements BaseEvent {
  event_type = 'TSB Financials Exported';

  constructor(
    public event_properties?: TsbFinancialsExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbHelpLinkClicked implements BaseEvent {
  event_type = 'TSB Help Link Clicked';

  constructor(
    public event_properties: TsbHelpLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbRegistrationActionClicked implements BaseEvent {
  event_type = 'TSB Registration Action Clicked';

  constructor(
    public event_properties?: TsbRegistrationActionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsbRegistrationExported implements BaseEvent {
  event_type = 'TSB Registration Exported';

  constructor(
    public event_properties?: TsbRegistrationExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * End User Registration Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/End%20User%20Registration%20Form%20Viewed)
   *
   * Event to track when a user views the registration form for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param properties The event's properties (e.g. form_id)
   * @param options Amplitude event options.
   */
  endUserRegistrationFormViewed(
    properties: EndUserRegistrationFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EndUserRegistrationFormViewed(properties), options);
  }

  /**
   * Reg Wizard Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Reg%20Wizard%20Step%20Completed)
   *
   * Event to track completion of a step in the registration wizard process for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param properties The event's properties (e.g. form_id)
   * @param options Amplitude event options.
   */
  regWizardStepCompleted(
    properties: RegWizardStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegWizardStepCompleted(properties), options);
  }

  /**
   * Reg Wizard Step Skipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Reg%20Wizard%20Step%20Skipped)
   *
   * Event to track completion of a step in the registration wizard process for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param properties The event's properties (e.g. form_id)
   * @param options Amplitude event options.
   */
  regWizardStepSkipped(
    properties: RegWizardStepSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegWizardStepSkipped(properties), options);
  }

  /**
   * Registration Wizard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Registration%20Wizard%20Viewed)
   *
   * A TSB staff member views the Reg form setup page.
   *
   * Owner: Alan Hull
   *
   * @param properties The event's properties (e.g. organization_id)
   * @param options Amplitude event options.
   */
  registrationWizardViewed(
    properties: RegistrationWizardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationWizardViewed(properties), options);
  }

  /**
   * Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Screen%20Viewed)
   *
   * An event to help us understand [which pages](https://miro.com/app/board/uXjVKHTMMoQ=/) users are viewing in a given session in the TSB web applications.
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. screen_name)
   * @param options Amplitude event options.
   */
  screenViewed(
    properties: ScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScreenViewed(properties), options);
  }

  /**
   * Season Invoice Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Season%20Invoice%20Click)
   *
   * Event triggered when a user clicks on either batch of season invoices  or individual invoices from TSB.
   *
   * @param properties The event's properties (e.g. season_invoice_tab_clicked)
   * @param options Amplitude event options.
   */
  seasonInvoiceClick(
    properties: SeasonInvoiceClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeasonInvoiceClick(properties), options);
  }

  /**
   * TSB Financial Action Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Financial%20Action%20Clicked)
   *
   * Event to track when a user clicks on a financial action within Teamsnap For Business
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. financial_action)
   * @param options Amplitude event options.
   */
  tsbFinancialActionClicked(
    properties?: TsbFinancialActionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbFinancialActionClicked(properties), options);
  }

  /**
   * TSB Financial Date Range Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Financial%20Date%20Range%20Changed)
   *
   * This event tracks when the date range is changed in the TSB Financial section
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. financial_date_option)
   * @param options Amplitude event options.
   */
  tsbFinancialDateRangeChanged(
    properties?: TsbFinancialDateRangeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbFinancialDateRangeChanged(properties), options);
  }

  /**
   * TSB Financial Filter Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Financial%20Filter%20Changed)
   *
   * Event to track when a user changes the financial filters in Teamsnap for Business
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. financial_filter)
   * @param options Amplitude event options.
   */
  tsbFinancialFilterChanged(
    properties?: TsbFinancialFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbFinancialFilterChanged(properties), options);
  }

  /**
   * TSB Financials Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Financials%20Exported)
   *
   * Event to track when financial data is exported within Teamsnap for Business
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. financial_report)
   * @param options Amplitude event options.
   */
  tsbFinancialsExported(
    properties?: TsbFinancialsExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbFinancialsExported(properties), options);
  }

  /**
   * TSB Help Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Help%20Link%20Clicked)
   *
   * Event to track when users click on the help link within Teamsnap for Business
   *
   * Owner: Christina Parent
   *
   * @param properties The event's properties (e.g. organization_id)
   * @param options Amplitude event options.
   */
  tsbHelpLinkClicked(
    properties: TsbHelpLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbHelpLinkClicked(properties), options);
  }

  /**
   * TSB Registration Action Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Registration%20Action%20Clicked)
   *
   * **Event to track when a user clicks on the action to manage registration records in Teamsnap For Business.**
   *
   * @param properties The event's properties (e.g. registration_action)
   * @param options Amplitude event options.
   */
  tsbRegistrationActionClicked(
    properties?: TsbRegistrationActionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbRegistrationActionClicked(properties), options);
  }

  /**
   * TSB Registration Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Registration%20Exported)
   *
   * **Event indicating that a registration export has been performed within Teamsnap for Business.**
   *
   * @param properties The event's properties (e.g. registration_export_type)
   * @param options Amplitude event options.
   */
  tsbRegistrationExported(
    properties?: TsbRegistrationExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TsbRegistrationExported(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
